import logo from './logo.png';
import './App.css';

function App() {
  window.location.replace('https://min.b.energy');
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p>Vent venligst, mens vi stiller dig videre.</p>
      </header>
    </div>
  );
}

export default App;
